import { useState } from "react"
import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicePointMessagesRow } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDevicePointMessages/ParadiseDevicePointMessagesRow"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchParadiseDevicePointMessages } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { TextButton } from "src/ui/Button/TextButton"
import { spacing } from "src/ui/spacing"

const LIMIT = 50

export function ParadiseDevicePointMessages({
  deviceId,
}: {
  deviceId: string
}) {
  const [page, setPage] = useState(1)
  const [selectedPointMessageIndex, setSelectedPointMessageIndex] = useState<
    number | null
  >(null)

  const fetchedPointMessages = useFetchParadiseDevicePointMessages({
    deviceId,
    filter: {
      limit: LIMIT,
      page: page,
    },
  })

  /*
    Here we fetch the next set of logs to check if there are any more
    This data is used to render the "Next" button in pagination
    We have to do this due to backend only returning logs and not "total_count" for this endpoint

    This is also why we do not use <Pager /> component for pagination
  */
  const nextFetchedPointMessages = useFetchParadiseDevicePointMessages({
    deviceId,
    filter: {
      limit: LIMIT,
      page: page + 1,
    },
  })
  const nextData = nextFetchedPointMessages.data

  const headers = [
    <div key={"message-type"}>Message type</div>,
    <div key={"received-at"}>Received at</div>,
    <div key={"misc"}></div>,
  ]

  const rows =
    fetchedPointMessages.data?.messages.map((message, i) => (
      <ParadiseDevicePointMessagesRow
        key={i}
        message={message}
        deviceId={deviceId}
        open={selectedPointMessageIndex === i}
        onClose={() => setSelectedPointMessageIndex(null)}
      />
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Point messages" />

      <ParadiseTable
        header={headers}
        rows={rows}
        templateColumns="auto 1fr auto"
        onRowClick={(index) => {
          setSelectedPointMessageIndex(index)
        }}
        error={{
          hasError: fetchedPointMessages.isError,
          title: fetchedPointMessages.error?.message,
        }}
      />

      <PaginationWrapper>
        {(page > 1 || (nextData && nextData.messages.length > 0)) && (
          <>
            <TextButton
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 1}
            >
              Previous
            </TextButton>
            <TextButton
              onClick={() => setPage((prev) => prev + 1)}
              disabled={nextData && nextData.messages.length === 0}
            >
              Next
            </TextButton>
          </>
        )}
      </PaginationWrapper>
    </div>
  )
}

const PaginationWrapper = styled.div`
  margin-block-start: ${spacing.L};
  display: flex;
  align-items: center;
  gap: ${spacing.L};
  justify-content: flex-end;
`
