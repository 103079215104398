import styled from "styled-components"

import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseDeviceFirmwareConfirmation({
  title,
  description,
  fwNumber,
  tags,
}: {
  title: string
  description: Maybe<string>
  fwNumber: Maybe<number>
  tags: string[]
}) {
  return (
    <ConfirmWrapper>
      <MText variant="subtitle">{title}</MText>
      <div>
        <MText variant="bodyS" color="secondary">
          Description
        </MText>
        <MText variant="body">{description || "-"}</MText>
      </div>
      <div>
        <MText variant="bodyS" color="secondary">
          Name
        </MText>
        <MText variant="body">{fwNumber || "-"}</MText>
      </div>
      <div>
        <MText variant="bodyS" color="secondary">
          Tags
        </MText>
        {tags.length > 0 ? (
          <PillsWrapper>
            {tags.map((tag) => (
              <MBadge key={tag}>{tag}</MBadge>
            ))}
          </PillsWrapper>
        ) : (
          "-"
        )}
      </div>
    </ConfirmWrapper>
  )
}

const ConfirmWrapper = styled(MBanner)`
  display: grid;
  gap: ${spacing.M};
`
