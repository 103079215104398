import { useState } from "react"
import styled from "styled-components"

import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { TParadiceDeviceCellular } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { colorsLegacy } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { JsonTree } from "src/ui/JsonTree/JsonTree"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { isNil } from "src/utils/genericUtil"

const cellular_registration_status = {
  0: "Not registered, idling",
  1: "Registered, home network",
  2: "Searching for networks",
  3: "Registration denied",
  4: "No service",
  5: "Registered, roaming",
}

const cellular_access_type = {
  0: "GSM",
  7: "E-UTRAN",
  9: "E-UTRAN NB-S1",
}

export function ParadiseDeviceCellular({
  cellular,
}: {
  cellular: TParadiceDeviceCellular
}) {
  const [showRawDialog, setShowRawDialog] = useState(false)

  return (
    <div>
      <ExpandableSection
        title="Cellular"
        buttonLabel="Raw"
        onButtonClick={() => setShowRawDialog(true)}
        initialOpen
      >
        <ExpandableWrapper>
          <ParadiseSettingStatic title="IMEI" value={cellular.imei || "-"} />
          <ParadiseSettingStatic title="EID" value={cellular.eid || "-"} />
          <ParadiseSettingStatic
            title="RSP"
            value={cellular.esim_rsp_supported ? "RSP" : "-"}
          />
          <ParadiseSettingStatic title="Model" value={cellular.model || "-"} />
          <ParadiseSettingStatic
            title="Firmware"
            value={cellular.firmware_version || "-"}
          />
          <ParadiseSettingStatic title="ICCID" value={cellular.iccid || "-"} />
          <ParadiseSettingStatic title="IMSI" value={cellular.imsi || "-"} />
          <ParadiseSettingStatic
            title="Registration status"
            value={
              cellular.registration_status
                ? cellular_registration_status[cellular.registration_status]
                : "-"
            }
          />

          <ParadiseSettingStatic
            title="Operator"
            value={cellular.operator || "-"}
          />
          <ParadiseSettingStatic
            title="Access type"
            value={
              cellular.access_type
                ? cellular_access_type[cellular.access_type]
                : "-"
            }
          />
          <ParadiseSettingStatic
            title="Cell id"
            value={!isNil(cellular.cell_id) ? cellular.cell_id : "-"}
          />
          <ParadiseSettingStatic
            title="RSRP"
            value={cellular.rsrp ? `${cellular.rsrp} dBm` : "-"}
          />
          <ParadiseSettingStatic
            title="RSRQ"
            value={cellular.rsrq ? `${cellular.rsrq} dB` : "-"}
          />
          {cellular.access_type === 0 && (
            <ParadiseSettingStatic
              title="RSSI"
              value={cellular.rssi ? `${cellular.rssi} dBm` : "-"}
            />
          )}
          <ParadiseSettingStatic
            title="WAN"
            value={cellular.wan_available ? cellular.ipv4_address : "-"}
          />
          <ParadiseSettingStatic
            title="EDRX"
            value={
              <ValueWrapper>
                <div>
                  <MText variant="nano">Req</MText>
                  <MText variant="body">
                    {!isNil(cellular.edrx_req) ? cellular.edrx_req : "-"}
                  </MText>
                </div>
                <div>
                  <MText variant="nano">Net</MText>
                  <MText variant="body">
                    {!isNil(cellular.edrx_nw) ? cellular.edrx_nw : "-"}
                  </MText>
                </div>
                <div>
                  <MText variant="nano">PTW</MText>
                  <MText variant="body">
                    {!isNil(cellular.edrx_ptw) ? cellular.edrx_ptw : "-"}
                  </MText>
                </div>
              </ValueWrapper>
            }
          />
          <ParadiseSettingStatic
            title="PSM"
            value={
              <ValueWrapper>
                <div>
                  <MText variant="nano">TAU</MText>
                  <MText variant="body">
                    {!isNil(cellular.psm_tau_nw)
                      ? `${cellular.psm_tau_nw} (${cellular.psm_tau_req})`
                      : "-"}
                  </MText>
                </div>
                <div>
                  <MText variant="nano">Active time</MText>
                  <MText variant="body">
                    {!isNil(cellular.psm_act_nw)
                      ? `${cellular.psm_act_nw} (${cellular.psm_act_req})`
                      : "-"}
                  </MText>
                </div>
              </ValueWrapper>
            }
          />
        </ExpandableWrapper>
      </ExpandableSection>
      <MDialog
        title="Raw cellular data"
        open={showRawDialog}
        onClose={() => setShowRawDialog(false)}
      >
        <JsonTree json={cellular} />
      </MDialog>
    </div>
  )
}

const ExpandableWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
  padding-block: ${spacing.S};
  font-size: 0.875rem;

  & > * {
    border-bottom: 1px solid ${colorsLegacy.divider};
    padding-bottom: ${spacing.XS2};
  }
`
const ValueWrapper = styled.div`
  display: flex;
  gap: ${spacing.L};
`
