import styled from "styled-components"

import { MountStatus } from "src/components/Devices/MountStatus"
import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { StatusWrapper } from "src/components/Paradise/sharedStyles"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { TParadiseDevice } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { WifiIcon } from "src/ui/WifiIcon/WifiIcon"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseDeviceMeta({ device }: { device: TParadiseDevice }) {
  return (
    <Box>
      <ParadiseDetailsHeading>{device.description}</ParadiseDetailsHeading>

      <DeviceMetaBox>
        <MTooltip
          title={
            <>
              <MText variant="subtitle" color="secondary">
                Last heard
              </MText>
              <MText variant="body">
                {formatUtcDate({ date: device.last_heard_from_at })}
              </MText>
            </>
          }
        >
          <div>
            <OnlineStatus online={!device.offline} />
          </div>
        </MTooltip>

        <StatusWrapper>
          <BatteryAndChargeStatusIcon
            percent={device.battery.percent}
            offline={device.offline}
            chargeStatus={device.charge_status}
          />
          {device.battery.voltage && (
            <MText variant="bodyS" color="secondary">
              {device.battery.voltage.toFixed(2)}V
            </MText>
          )}
        </StatusWrapper>

        <StatusWrapper>
          <WifiIcon
            quality={device.wlan?.signal_quality}
            offline={device.offline}
          />
          {device.wlan?.rssi && (
            <MText variant="bodyS" color="secondary">
              {device.wlan.rssi}
            </MText>
          )}
        </StatusWrapper>

        <StatusWrapper>
          <MountStatus
            deviceType={getDeviceHardwareType(device.hardware_version).type}
            offline={device.offline}
            status={device.mount_status}
          />
        </StatusWrapper>
      </DeviceMetaBox>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const DeviceMetaBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
`
