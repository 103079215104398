import { ParadiseDeviceFirmwareConfirmation } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmwareConfirmation"
import { IParadiseFirmware } from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseDeviceClearFirmware({
  currentWantedFirmware,
}: {
  currentWantedFirmware: Maybe<IParadiseFirmware>
}) {
  if (!currentWantedFirmware) {
    return null
  }

  return (
    <ParadiseDeviceFirmwareConfirmation
      title="Currently wanted firmware"
      description={currentWantedFirmware.description}
      fwNumber={currentWantedFirmware.name}
      tags={currentWantedFirmware.tags}
    />
  )
}
