import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDeviceActions } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceActions"
import { ParadiseDeviceCellular } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceCellular/ParadiseDeviceCellular"
import { ParadiseDeviceEvents } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceEvents/ParadiseDeviceEvents"
import { ParadiseDeviceMeta } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceMeta"
import { ParadiseDeviceOverview } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceOverview"
import { ParadiseDevicePointMessages } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDevicePointMessages/ParadiseDevicePointMessages"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchParadiseDevice } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseDeviceDetails({ deviceId }: { deviceId: string }) {
  const { t } = useTranslate()

  const fetchedDevice = useFetchParadiseDevice({
    deviceId,
  })

  useAddRecentlyViewedPage({
    title: fetchedDevice.data?.description,
    enitytId: fetchedDevice.data?.device_id,
    type: "Device",
    enabled: fetchedDevice.isSuccess,
  })

  if (fetchedDevice.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchedDevice.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const device = fetchedDevice.data

  return (
    <ParadiseDetailsLayout
      meta={<ParadiseDeviceMeta device={device} />}
      actions={<ParadiseDeviceActions device={device} />}
      banner={
        device.deleted_at && (
          <MBanner type="error" fullWidth>
            Device is deleted
          </MBanner>
        )
      }
      left={
        <>
          <ParadiseDeviceOverview device={device} />
          {/* device.cellular might be an empty object or undefined.
          Checking modem_present here to only render when cellular contains
          interesting data */}
          {device.cellular?.modem_present && (
            <ParadiseDeviceCellular cellular={device.cellular} />
          )}
        </>
      }
      right={
        <>
          <ParadiseDevicePointMessages deviceId={device.device_id} />
          <ParadiseDeviceEvents deviceId={device.device_id} />
        </>
      }
      breadcrumbs={[
        {
          label: "Devices",
          to: Routes.ParadiseDevices.location(),
        },
        {
          label: device.device_id,
          to: Routes.ParadiseDevice.location(device.device_id),
        },
      ]}
      breadcrumbCopyValue={device.device_id}
    />
  )
}
